import React from "react";
import { Layout, Seo } from "components/common";
import { Intro, Skills, Contact, Projects } from "components/landing";
import { Grid } from "../components/landing/Projects/styles"
import Folio from '../components/common/folioLayout/folio'
import "./style.css"
import { StaticImage } from "gatsby-plugin-image"

export const Landscapes = () => {
    return (
        <>
            <div class="gre">
                <Layout>
                    <Seo />
                    <Folio pageTitle="Landscape Pixls">
                        {/* <Grid> */}
                        <div class="row">
                            <div class="column">
                                <StaticImage src='../images/landscapes/1.JPG' layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/2.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/3.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/12.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/13.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/28.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/31.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/35.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src="../images/landscapes/4.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/5.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/14.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/15.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/17.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/29.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/32.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/36.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src='../images/landscapes/6.JPG' layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/7.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/18.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/19.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/27.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/21.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/33.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src="../images/landscapes/22.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/23.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/24.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/25.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/26.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/20.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/30.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/landscapes/34.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                        </div>
                        {/* </Grid> */}
                    </Folio>

                </Layout>
            </div>
        </>
    );
};


export default Landscapes;